<template>
    <div class="project">
        <div class="intro grid">
            <h1>{{$t('CbrRijdata.title')}}</h1>
            <div class="cell">
                <p>{{$t('CbrRijdata.intro')}}</p>
            </div>
            <div class="cell">
                <dl>
                    <dt>{{$t('project.client')}}</dt>
                    <dd>{{$t('CbrRijdata.client')}}</dd>
                    <dt>{{$t('project.at')}}</dt>
                    <dd>{{$t('CbrRijdata.at')}}</dd>
                    <dt>{{$t('project.role')}}</dt>
                    <dd>{{$t('CbrRijdata.role')}}</dd>
                    <dt>{{$t('project.timeframe')}}</dt>
                    <dd>2022 - 2023</dd>
                    <!-- <dt>{{$t('project.prototype')}}</dt> -->
                    <!-- <dd><a href="https://xd.adobe.com/view/d6227abe-9886-4b00-4906-f2934052629e-a013/" class="button" target="_blank">Fast & Fluid design system</a></dd> -->
                </dl>
            </div>
        </div>
        <div class="case">
            <div class="layout">
                <div class="layout__item layout__item--12">
                    <img src="@/assets/images/cases/cbr-rijdata/1-cover.jpg" srcset="@/assets/images/cases/cbr-rijdata/1-cover@2x.jpg 2x" alt="CBR Rijdata dashboard">
                    <p class="caption">{{$t('CbrRijdata.coverImage')}}</p>
                </div>
                <!-- <div class="layout__item layout__item--12">
                    <h3>{{$t('CbrRijdata.typefaceTitle')}}</h3>
                    <img src="@/assets/images/cases/fast-fluid/2-typeface.jpg" srcset="@/assets/images/cases/fast-fluid/2-typeface@2x.jpg 2x" alt="Typeface selection">
                    <p class="caption">{{$t('CbrRijdata.typeface')}}</p>
                </div>
                <div class="layout__item layout__item--3">
                    <h3 class="h4">{{$t('CbrRijdata.colorsTitle')}}</h3>
                    <p>{{$t('CbrRijdata.colors')}}</p>
                    <h3 class="h4">{{$t('CbrRijdata.contrastTitle')}}</h3>
                    <p>{{$t('CbrRijdata.contrast')}}</p>
                </div>
                <div class="layout__item layout__item--9">
                    <img src="@/assets/images/cases/fast-fluid/3-colors.jpg" srcset="@/assets/images/cases/fast-fluid/3-colors@2x.jpg 2x" alt="Color tyles">
                </div>
                <div class="layout__item layout__item--12">
                    <h3>{{$t('CbrRijdata.elementsTitle')}}</h3>
                    <img src="@/assets/images/cases/fast-fluid/4-elements.jpg" srcset="@/assets/images/cases/fast-fluid/4-elements@2x.jpg 2x" alt="Form elements">
                    <p>{{$t('CbrRijdata.elements')}}</p>
                </div> -->
                <!-- <div class="layout__item layout__item--12">
                    <a href="https://xd.adobe.com/view/d6227abe-9886-4b00-4906-f2934052629e-a013/" class="button" target="_blank">Fast & Fluid design system</a>
                </div> -->
            </div>
        </div>
        <OtherCases prev-route="/projects/all-united" prev-title="allUnited" next-route="/projects/hbn" next-title="hbn" />
    </div>
</template>
<script>
import OtherCases from '@/components/OtherCases.vue'

export default {
    components: {
        OtherCases
    }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/_Project.scss';
</style>